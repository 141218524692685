const jaTranslations = {
/*********************
  表題、画面名
*********************/
  home : 'ホーム',
  management: '管理',
  account: 'アカウント',
  servicemenu:'サービスメニュー',
  asoko2: 'ア・ソコ２',
  asoko2cmt:'倉庫内オペレーション最適化シミュレーター',
  asokoplus:'ア・ソコ＋',
  asokopluscmt:'倉庫内3Dレイアウトシミュレーター',
  newexec: '新規・実行',
  reshis:'結果・履歴',
  picking_newexec: '最適化ピッキングリスト 新規・実行',
  picking_reshis:'最適化ピッキングリスト 結果・履歴',
  simulation_newexec: '最適化シナリオ検証 新規・実行',
  simulation_reshis:'最適化シナリオ検証 結果・履歴',
  title_create_account: 'アカウントの新規追加',
  title_create_company: '法人名の新規追加',
  title_create_warehouse: '事業所名の新規追加',
  title_edit_account: 'アカウントの編集',
  title_edit_company: '法人名の編集',
  title_edit_warehouse: '事業所名の編集',
  opt_scenario_verif: '最適化シナリオ検証',
  opt_picking_list: '最適化ピッキングリスト',

/*********************
  ボタン
*********************/
  create_user: 'アカウントの新規追加',
  create_company: '法人名の新規追加',
  create_warehouse: '事業所名の新規追加',
  upload: 'アップロード',
  execute: '実行',
  register: '登録',
  edit: '編集',
  delete: '削除',
  cancel: 'キャンセル',
  back:'◀戻る',
  asoko2exec:'実行▶',

/*********************
  入力フィールド（ラベル）
*********************/
  email: 'Email',
  confirm_email: 'Email（確認）',
  password: 'パスワード',
  confirm_password: 'パスワード（確認）',
  account_name: 'アカウント名',
  company_name: '法人名',
  company_short_name: '法人コード',
  warehouse_name: '事業所名',
  warehouse_short_name: '事業所コード',
  full_name: '名前',
  last_name: '姓',
  first_name: '名',
  role: '権限',
  select_file_for_upload: '▶ クリックしてファイルを選択',
  message_upload_done: 'アップロード済',
  is_notification: 'シミュレーション完了通知をメールで受け取る',
  export_result_file: '出力結果ファイル',
  export_excel: 'csvの他に、Excel形式でも出力する',

/*********************
  表項目（ラベル）
*********************/
  simulation_model_files: 'シミュレーション・モデル',
  master_files: '条件・データファイル',
  updated_user: '更新ユーザ',
  executed_user: '実行ユーザ',
  master_kind: 'マスター種類',
  model_kind: 'モデル種類',
  exec_filename: '実行ファイル名',
  executed: '実行日時',
  updated: '更新日時',
  result: '結果',
  model_filename: 'モデルファイル名',
  master_filename: 'マスターファイル名',
  workload_list: '作業レポート',
  execution_log: '実行ログ',

/*********************
  マスタ種別
*********************/
  select_kind_of_master: 'マスタ種類を選択',
  parameter_master: '変数マスタ',
  merchandise_master: '商品マスタ',
  packing_master: '梱包マスタ',
  delivery_master: '配送マスタ',
  inventory_data: '在庫データ',
  shipment_data: '出庫データ',

/*********************
  権限
*********************/
  role_admin: '管理者',
  role_manager: '企業内管理者',
  role_user: '一般アカウント',
  role_viewer: '閲覧者',

/*********************
  モデル・マスター種別
*********************/
  file_simulation_model: 'シミュレーション',
  file_picking_model: 'ピッキング',
  file_data00: '00_変数設定',
  file_data01: '01_商品マスタ',
  file_data02: '02_梱包マスタ',
  file_data03: '03_配送マスタ',
  file_data04: '04_在庫データ',
  file_data05: '05_出庫データ',

/*********************
  メッセージ
*********************/
  exec_message: 'モデルのシミュレーションを実行しますか？',
  delete_message: '削除してよろしいでしょうか',
  login_error: 'ログインに失敗しました。メールアドレス又はパスワードを確認してください',
  email_does_not_match: 'メールアドレスが同一ではありません',
  password_does_not_match: 'パスワードが同一ではありません',
  assigned_warehouses_empty: '事業所を選択してください',
  upload_all_files: 'すべての種類のファイルをアップロードしてください',
  exec_in_progress: 'シミュレーション実行中',
  exec_is_done: 'シミュレーション実行完了',
  exec_has_error: 'シミュレーション実行エラー',
  company_short_name_validation_error: '「法人名（略称）」には大文字英字、数字のみを入力してください',
  warehouse_short_name_validation_error: '「事業所名（略称）」には大文字英字、数字のみを入力してください',
  email_invalid_format: 'メールアドレスの書式が正しくありません',
  email_duplicated: 'メールアドレスが既存のものと重複しています',
  company_code_duplicated: '法人コードが既存のものと重複しています',
  warehouse_duplicated: '事業所名と事業所コードが既存のものと重複しています',
  file_size_error: 'アップロードできるファイルのサイズは50MBまでです。',
  session_timeout: 'セッションがタイムアウトしました。再度ログインしてください。',

/*********************
  プラントシミュレーション エラーメッセージ
*********************/
  E_00_00_00: '正常終了',
  E_01_00_01: '変数設定ファイルの読み込みに失敗しました。',
  E_01_00_02: '変数設定ファイルの行・列定義が不正です。',
  E_01_00_03: '変数設定ファイルに不正な値が含まれています。',
  E_01_01_01: '商品マスタの読み込みに失敗しました。',
  E_01_01_02: '商品マスタの列定義が不正です。',
  E_01_01_03: '商品マスタに不正な値が含まれています。',
  E_01_02_01: '梱包マスタの読み込みに失敗しました。',
  E_01_02_02: '梱包マスタの列定義が不正です。',
  E_01_02_03: '梱包マスタに不正な値が含まれています。',
  E_01_03_01: '配送マスタの読み込みに失敗しました。',
  E_01_03_02: '配送マスタの列定義が不正です。',
  E_01_03_03: '配送マスタに不正な値が含まれています。',
  E_01_05_01: '出庫データの読み込みに失敗しました。',
  E_01_05_02: '出庫データの列定義が不正です。',
  E_01_05_03: '出庫データに不正な値が含まれています。',
  E_99_00_01: 'システムエラー。管理者にお問い合わせください。',
  E_99_01_01: 'システムエラー。管理者にお問い合わせください。',

  // Login and register screens
  createAccount: 'アカウントを作成',
  alreadyAccount: '既にアカウントをお持ちの方は',
  login: 'ログイン',
  loginAccount: 'アカウントへログイン',

  lang:'言語',

  logout: 'ログアウト',

  'Spatial information': '建物の情報を入力してください',
  'Cargo and storage type': '荷物の情報を入力してください',
  'Storage method': '保管方法を入力してください',
  'Direction of arrangement': '配列方向',
  'Constraints of passageway': '配置条件',
  'Constraints of layout': 'クリアランス',

  'Vertical Direction toward Truck Berth': 'バース（接車ライン）より垂直（縦）方向',
  'Horizontal Direction toward Truck Berth': 'バース（接車ライン）より水平（横）方向',

  'Pillar Span + Ceiling Height': '柱スパンと有効天井高',
  'Width': '間口',
  'Depth': '奥行',
  'Height': '高さ',
  'Weight': '重量',
  'Thickness': '奥行',
  'Width sections': '間口',
  'Depth sections': '奥行',

  'Floor load capacity': '床荷重',
  'Fire compartment': '防火区画',
  'Pillar size': '柱の太さ',
  'Thickness of spandrel wall': '腰壁の厚さ',

  'Direct storage': '直置き',
  'Pallet rack': 'パレットラック（重量棚）',
  'Nesting rack': '逆ネステナー',
  'Medium duty rack': '倉庫ラック（中量棚）',
  'Folding containers': 'オリコンケース',

  'Pallet': 'パレット',
  'JIS standard': '標準',
  'Beverage': '飲料',
  'EU standard': '欧州',
  'US standard': '米国',
  'Cold storage': '冷凍',
  'Rollbox pallet': 'かご台車',
  'Roll box pallet 1': 'タイプ①',
  'Roll box pallet 2': 'タイプ②',
  'Folding container': 'ケース単位',
  'Piece': 'ピース単位',

  'pallet_rack_2p_1s': '2枚/単立',
  'pallet_rack_1p_1s': '1枚/単立',
  'pallet_rack_2p_2s': '2枚/連結',
  'pallet_rack_1p_2s': '1枚/連結',
  'nesting_rack_1p_1s': '単立',
  'nesting_rack_2p_2s': '連結',
  'mdr_1w_1s': '半間/単立',
  'mdr_2w_1s': '1間/単立',
  'mdr_2w_2s': '半間/連結',
  'mdr_1w_2s': '1間/連結',
  'fc_1c_1s': '単立',
  'fc_1c_2s': '連結',
  'carton_boxes': '',

  'Cargo dimensions': '荷物の寸法',
  'Cargo weight': '荷物の重量',

  'Layout constraint inputs': '配列条件を入力してください',
  'Passageway width': '通路幅',
  'Main passageway width': '通路幅／メイン',
  'Sub passageway width': '通路幅／サブ',

  'From pillar': '柱より',
  'From firewall': '壁より／防火壁（腰壁）',
  'From fire shutter': '壁より／防火シャッター',
  'From beam': '梁より',
  'Storage weight': '保管方法の重量',

  'JPY/Tsubo': '円/坪',
  'Monthly rent per unit': '賃料単価を入力してください',

  plantsim : 'シミュレーション',
  users: 'ユーザ',
  companies: '企業',
  warehouses: '事業所',

  'Create new plan': '新しいプランを作成',

  'Download': 'ダウンロード',
  'Fullscreen': '全画面',
  'Visualization': 'レイアウト',
  Dashboard: 'ダッシュボード',

  psch: '柱スパンと有効天井高',
  efch: '有効天高',
  'Floor area': '面積',
  'Space volume': '容積',
  mrpu: '月額賃料',
  unitPrice: '単価',

  'Total pallets': '保管数量',
  'Pallets/Tsubo': '保管効率',
  'Storage cost': '保管費用',
  'Floor occupancy rate': '保管使用率',
  'Space occupancy rate': '空間使用率',
  'Total containers': '個数',
};

export default jaTranslations;
