const enTranslations = {
/*********************
  Captions and Screens name
*********************/
  home : 'Home',
  management: 'Management',
  account: 'Account',
  servicemenu:"Service Menu",
  asoko2: 'A-soko2',
  asoko2cmt:"Warehouse operation optimization simulator",
  asokoplus:'A-soko+',
  asokopluscmt:"Warehouse 3D layout simulator",
  newexec: 'NEW - EXECUTION',
  reshis:'RESULT - HISTORY',
  picking_newexec: 'Optimized picking list NEW - EXECUTION',
  picking_reshis:'Optimized picking list RESULT - HISTORY',
  simulation_newexec: 'Optimization scenario verification NEW - EXECUTION',
  simulation_reshis:'Optimization scenario verification RESULT - HISTORY',
  title_create_account: 'CREATE AN ACCOUNT',
  title_create_company: 'CREATE A COMPANY',
  title_create_warehouse: 'CREATE A WAREHOUSE',
  title_edit_account: 'EDIT AN ACCOUNT',
  title_edit_company: 'EDIT A COMPANY',
  title_edit_warehouse: 'EDIT A WAREHOUSE',
  opt_scenario_verif: 'Optimization scenario verification',
  opt_picking_list: 'Optimized picking list',

/*********************
  Buttons
*********************/
  create_user: 'Add account',
  create_company: 'Add company',
  create_warehouse: 'Add warehouse',
  upload: 'Upload',
  execute: 'Execute',
  register: 'Register',
  edit: 'Edit',
  delete: 'Delete',
  cancel: 'Cancel',
  back:'◀Back',
  asoko2exec:"RUN▶",

  /*********************
  Input fields "Labels"
*********************/
  email: 'Email',
  confirm_email: 'Confirm email',
  password: 'Password',
  confirm_password: 'Confirm Password',
  account_name: 'Account name',
  company_name: 'Company name',
  company_short_name: 'Company code',
  warehouse_name: 'Warehouse name',
  warehouse_short_name: 'Warehouse code',
  full_name: 'Full name',
  last_name: 'Last name',
  first_name: 'First name',
  role: 'Role',
  select_file_for_upload: '▶ Select a file for Upload',
  message_upload_done: 'Upload done',
  is_notification: 'Receive email notification of simulation completion',
  export_result_file: 'Exported result file',
  export_excel: 'Export files in Excel format in additional to csv',

/*********************
  List items "Labels"
*********************/
  simulation_model_files: 'Simulation model files',
  master_files: 'Model files',
  updated_user: 'Updated user',
  executed_user: 'Executed user',
  master_kind: 'Master kind',
  model_kind: 'Model kind',
  exec_filename: 'Execute filename',
  executed: 'Executed datetime',
  updated: 'Updated datetime',
  result: 'Result',
  model_filename: 'Model filename',
  master_filename: 'Master filename',
  workload_list: 'Workload report',
  execution_log: 'Execution log',
  
/*********************
  Master Classification
*********************/
  select_kind_of_master: 'Select the kind of master',
  parameter_master: 'Parameter master',
  merchandise_master: 'Merchandise master',
  packing_master: 'Packing master',
  delivery_master: 'Delivery master',
  inventory_data: 'Inventory data',
  shipment_data: 'Shipment data',

/*********************
  Authorities
*********************/
  role_admin: 'Admin',
  role_manager: 'Manager',
  role_user: 'User',
  role_viewer: 'Viewer',

/*********************
  Model,Master kind
*********************/
  file_simulation_model: 'Simulation',
  file_picking_model: 'Picking',
  file_data00: '00_Parameter master',
  file_data01: '01_Merchandise master',
  file_data02: '02_Packing master',
  file_data03: '03_Deliverly master',
  file_data04: '04_Inventory data',
  file_data05: '05_Shipment data',

/*********************
  Messages
*********************/
  exec_message: 'Do you want to simulate the model?',
  delete_message: 'Are you sure you want to delete this record?',
  login_error: 'Login failed, check account or password',
  email_does_not_match: 'Email address does not match',
  password_does_not_match: 'Password does not match',
  assigned_warehouses_empty: 'Select warehouses',
  upload_all_files: 'Upload all kind of files',
  exec_in_progress: 'Simulation execution in progress',
  exec_is_done: 'Simulation execution is done',
  exec_has_error: 'Simulation execution has error',
  company_short_name_validation_error: 'Please enter only uppercase letters and numbers for company short name',
  warehouse_short_name_validation_error: 'Please enter only uppercase letters and numbers for warehouse short name',
  email_invalid_format: 'Invalid email address',
  email_duplicated: 'Email is duplicated with existing one',
  company_code_duplicated: 'Company code is duplicated with existing one',
  warehouse_duplicated: 'Warehouse name and code are duplicated with existing one',
  file_size_error: 'File size must be below 50MB.',
  session_timeout: 'Session timeout. Please login again.',

/*********************
  Plant Simulation Erroe Message
*********************/
  E_00_00_00: 'Completed.',
  E_01_00_01: 'An attempt to load the variable setting file failed.',
  E_01_00_02: 'The row and column definition in the variable settings file is invalid.',
  E_01_00_03: 'The variable configuration file contains an invalid value.',
  E_01_01_01: 'Failed to load product master.',
  E_01_01_02: 'The column definition of the product master is invalid.',
  E_01_01_03: 'The product master contains an invalid value.',
  E_01_02_01: 'Failed to load the packaging master.',
  E_01_02_02: 'The column definition of the packaging master is invalid.',
  E_01_02_03: 'The packaging master contains invalid values.',
  E_01_03_01: 'Failed to read delivery master.',
  E_01_03_02: 'The column definition of the delivery master is invalid.',
  E_01_03_03: 'The shipping master contains invalid values.',
  E_01_05_01: 'Failed to read shipping data.',
  E_01_05_02: 'The column definition for the shipping data is invalid.',
  E_01_05_03: 'The shipment data contains an invalid value.',
  E_99_00_01: 'System error, please contact your administrator.',
  E_99_01_01: 'System error, please contact your administrator.',

  // Login and register screens
  createAccount: 'Create an account',
  alreadyAccount: 'Have an account?',
  login: 'Login',
  loginAccount: 'Login to your account',

  lang:'Language',


  "pallet_rack_2p_1s": "2 Pallets / 1 Side",
  "pallet_rack_1p_1s": "1 Pallet / 1 Side",
  "pallet_rack_2p_2s": "2 Pallets / 2 Sides",
  "pallet_rack_1p_2s": "1 Pallet / 2 Sides",
  "nesting_rack_1p_1s": "1 Pallet / 1 Side",
  "nesting_rack_2p_2s": "2 Pallets / 2 Sides",
  "mdr_1w_1s": "0.9m / 1 Side",
  "mdr_2w_1s": "1.8m / 1 Side",
  "mdr_2w_2s": "0.9m / 2 Sides",
  "mdr_1w_2s": "1.8m / 2 Sides",
  "fc_1c_1s": "1 Case / 1 Side",
  "fc_1c_2s": "1 Case / 2 Sides",
  "carton_boxes": "",

  psch: "Pillar Span + Ceiling Height",
  efch: "Effect ceil height",
  mrpu: "Monthly rent per unit",
  unitPrice: "JPY/Tsubo",
};

export default enTranslations;
